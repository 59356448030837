import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

import { preventBodyScroll } from '../common/preventBodyScroll';

(function($) {

    let main_header = $('.main-header');
    let body = $('body');

    window.scrollingTasks = [];

    window.windowOnLoad = function(callback) {
        if (window.addEventListener) {
            window.addEventListener('load', callback, false);
        }
        else if (window.attachEvent) {
            window.attachEvent('onload', callback);
        }
    };
    window.isInViewport = function(elem) {
        let elementTop = elem.offset().top;
        let elementBottom = elementTop + elem.outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        //return elementBottom > viewportTop && elementTop < viewportBottom;
        return elementTop < viewportBottom;
    };

    /* add/remove class active for nav */
    function setActiveUrl(){
        window.urlLocation = window.location.pathname.toString();
        $('.header-bottom ul li').find('active').removeClass('active');

        switch (urlLocation){
            case "/" :
                $('.header-bottom ul li a.home-url').addClass('active');
                break;
            case "/getting-started" :
                $('.header-bottom ul li a.getting-started-url').addClass('active');
                break;
            case "/stocks":
                $('.header-bottom ul li a.products-url').addClass('active');
                break;
            case "/fixed-interest-account":
                $('.header-bottom ul li a.products-url').addClass('active');
                break;
            case "/web-platform":
                $('.header-bottom ul li a.investing-center-url').addClass('active');
                break;
            case "/mobile-platform":
                $('.header-bottom ul li a.investing-center-url').addClass('active');
                break;
            case "/live-rates":
                $('.header-bottom ul li a.investing-center-url').addClass('active');
                break;
            case "/economic-calendar":
                $('.header-bottom ul li a.investing-center-url').addClass('active');
                break;
            case "/about-us":
                $('.header-bottom ul li a.about-us-url').addClass('active');
                break;
            case "/contact-us":
                $('.header-bottom ul li a.contact-us-url').addClass('active');
                break;
            case "/faqs":
                $('.header-bottom ul li a.about-us-url').addClass('active');
                break;
            default:
            // only if case does not exist
        }
    }

    function setBreadCrumbs(){
        window.urlLocation = window.location.pathname.toString();
        
        var urlArr = window.urlLocation.split("/");
        
        let html = '<div class="bread_crumbs"><ul class="container"><li><a href="/">Home</a></li>';
        var url = "";
        urlArr.forEach((item) => {
            if (item && item !== "" && typeof item !== undefined ){
                url += '/' + item;
                
                if (item === "stocks" || item === "fixed-interest-account"){
                    html  += '<li>products</li>';
                    if(item === "stocks"){
                        html  += '<li><a href="'+ url +'">Stock Derivatives</a></li>';
                    }else{
                        html  += '<li><a href="'+ url +'">'+item.replace(/-/g, ' ')+'</a></li>';
                    }
                }else if(item === "web-platform" || item === "mobile-platform" || item === "live-rates" || item === "economic-calendar"){
                    html  += '<li>Investing center</li>';
                    html  += '<li><a href="'+ url +'">'+item.replace('-', ' ')+'</a></li>';
                }else if(item === "about-us" || item === "contact-us" || item === "faqs"){
                    html  += '<li>our company</li>';
                    html  += '<li><a href="'+ url +'">'+item.replace('-', ' ')+'</a></li>';
                }else{
                    html  += '<li><a href="'+ url +'">'+item.replace('-', ' ')+'</a></li>';
                }

                
            }
            
        });

        html += '</ul></div>';
        $('body').prepend(html);
    }


    window.windowOnLoad(function(e) {

        window.__dlw = {
            rc: function(e) {
                var t = new RegExp("(?:^| )" + e + "=([^;]*)", "i"),
                    i = document.cookie.match(t);
                return i && 2 === i.length ? decodeURIComponent(i[1]) : null;
            },
            sc: function(e, t, i) {
                var n = "";
                if (i) {
                    var l = new Date();
                    l.setTime(l.getTime() + 24 * (void 0 !== i ? i : 3) * 60 * 60 * 1e3), n = "; expires=" + l.toGMTString()
                }
                document.cookie = e + "=" + encodeURIComponent(t) + n + "; path=/;";
            },
            store: function(e, t, i) {
                i = i || 3;
                var n = {};
                if (n[e] = t, dataLayer.push(n), 3 !== i) {
                    var l = window.__dlw.rc("cdl" + i),
                        r = [];
                    if (null != l)
                        for (var c = (r = l.split(";")).length; c--;)
                            if (r[c].split(":")[0] === e) {
                                r.splice(c, 1);
                                break;
                            }
                    r.push(e + ":" + t.replace(/;/g, "")); window.__dlw.sc("cdl" + i, r.join(";"), 1 == i && 1e3);
                }
            },
            init: function() {
                var e = (window.__dlw.rc("cdl1") || "") + ";" + (window.__dlw.rc("cdl2") || "");
                if (";" !== e) {
                    let n;
                    for (let t = e.split(";"), n = {}, i = t.length; i--;) "" !== t[i] && (n[t[i].split(":")[0]] = t[i].split(":")[1]);
                    dataLayer.push(n);
                }
            }
        };
        window.__dlw.init();

        window.dataLayer.push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });
        const script = document.createElement('script');
        document.body.appendChild(script);
        script.async = true;
        script.defer = true;
        script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-V3CB';



        if (document.location.hash !== '' && $(document.location.hash).length > 0) {
            var hash = document.location.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top - main_header.height()
            }, 1e3);
        }


        $('.toggle').on('click', function(){
            if (typeof(slidedown_callback)!='undefined') sdcallback($(this));
        });




        if(!$('#page').hasClass('home') && $(window).width() > 767){
          //  setBreadCrumbs();
        }

        if($(window).width() > 767){
            setActiveUrl();
        }
    });

        window.savecookie = function(a, b, c) {
            var d = new Date();
            d.setTime(d.getTime() + 864E5 * ("undefined" != typeof c ? c : 3));
            c = "; expires\x3d" + d.toGMTString();
            document.cookie = a + "\x3d" + b + c + "; path\x3d/;domain\x3d" + document.domain.replace(/^[^\.]+/, '')
        };

        window.readcookie = function(a) {
            a = new RegExp("(?:^| )" + a + "\x3d([^;]*)","i");
            return (a = document.cookie.match(a)) && 2 == a.length ? a[1] : null;
        };


        window.openLightBox = function(name,type,length,url) {
            $('#totalPage').addClass('totalOn').fadeTo(260,0.5,function() {
                $('#'+name+'').fadeIn(600);
            });
            if (type == 'timeout') setTimeout(function(){closeLightBox(name)}, length);
            if (type == 'redirect') setTimeout(function(){
                window.location.href = url;
            }, length);
            if (type == 'loadframe') {
                $('#'+name+'').load(url);
            }

            preventBodyScroll();
        };

        window.closeLightBox = function (name, callback) {
            $('#'+name+'').fadeOut(300,function() {
                $('#totalPage').fadeTo(500,0,function() {
                    $(this).removeClass('totalOn');
                    if (typeof(callback) == "function") callback();
                });
            });

            preventBodyScroll();
        };


        window.savePPCookieA  = function () {
            window.savecookie("fx_cn","1");
            $('#cookie-notif').hide();

            //window.setFixedHeader();
        };

        window.loadPrivacyLinkA = function() {
            $('#pp_agreement').find('#pp_content').load("/legal/io-cookie-policy.html" ,function(){window.openLightBox("pp_agreement");});
        };

        window.toggleCookieNotification = function() {

            $('#cookie-notif')[window.readcookie('fx_cn') ? 'hide' : 'show']();
          //  window.setFixedHeader();
        };

     //ndow.setFixedHeader = () => {
//body.css('margin-top', main_header.height());
//};

        toggleCookieNotification();

        window.geoipcookie = window.readcookie('geoip');

        function replaceLoginUrl(){
            $('.btn-login').attr("href", "//trader.fihtrader.com/webpl3/Account/Login/Lang/Arabic");
        }
        
        if (window.geoipcookie === 'SA'){
            replaceLoginUrl();
        }


    let scrolling = false;

    $(document).on('click', '.panel-default .panel-heading', function (e) {
        var parent = $(this).parent();
        if(!$(parent).find('.panel-collapse').hasClass('in')){

            $('.panel-heading').addClass('collapsed');
            $(parent).find('.panel-heading').removeClass('collapsed');
            $('.panel-collapse.in').removeClass('in');
            $(parent).find('.panel-collapse').addClass('in').slideDown(1000);


            $([document.documentElement, document.body]).animate({
                scrollTop: $(parent).offset().top - main_header.height() -10
            }, 1000);

        }else{

            $('.panel-collapse.in').removeClass('in').slideUp(1000);
            $(parent).find('.panel-heading').addClass('collapsed');
        }
    });

    let dropdown_menu = $('.dropdown_menu_wrapper');

    dropdown_menu.hover(function(){
        
        $(this).find('.dropdown_menu').stop().slideToggle('slow');
        $(this).toggleClass('open');
    });

    let dropdown_toggle = $('.dropdown-toggle');

    dropdown_toggle.click(function(){

        $('.dropdown-menu').slideUp('slow');

        if($(this).hasClass('open')){
            $(this).next().slideUp('slow');  
            $(this).parent().removeClass('open');
            $(this).removeClass('open');
        }else{
            dropdown_toggle.next().slideUp('slow');
            dropdown_toggle.parent().removeClass('open');
            dropdown_toggle.removeClass('open');
            $(this).next().slideDown('slow');  
            $(this).parent().addClass('open');
            $(this).addClass('open');
        }
    
        
    });

    if($(window).width() > 922) {

        $(document).on('click', function (event) {
            if (!$(event.target).closest(".dropdown-toggle").length && dropdown_toggle.hasClass('open')) {

                dropdown_toggle.next().slideUp('slow');
                dropdown_toggle.removeClass('open');
                $('.dropdown').removeClass('open');
            }
        });
    }


    $('#lang_menu, .close_lang').click(function(){
        var options = { direction: 'bottom' };
        $('.lang_list_wrap').toggleClass('open');
    });

    $('.navbar-toggle, .close_mob_menu').click(function(){
        var options = { direction: 'left' };
        $('#slidemenu').toggleClass('open');
        $('body').toggleClass('mobMenuOpen');
    });




    function lazyImageBF(){
        var mobileImage = [].slice.call(document.getElementsByClassName("lazy-image-bf-mob"));{
            if($(window).width() < 620){
                mobileImage.forEach.call(mobileImage, function (mobileImage) {
                    mobileImage.classList.add("lazy-image-bf");
                    mobileImage.classList.remove("lazy-image-bf-mob");
                });
            }
        }
        var lazyImages = [].slice.call(document.getElementsByClassName("lazy-image-bf"));
        lazyImages.forEach.call(lazyImages, function (lazyImage) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy-image-bf");
        });
    }


    let footer_wrapper= $('.footer-shared');
    let loadingFooter = false, footerLoaded = false;

//Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf
    let lazyImage_wrapper = $('.lazy-image-bf');
    let loadingLazyImagesBF = false, lazyImagesBFLoaded = false;

    let cookie_notif = $('#cookie-notif');
    let cookie_notif_height = cookie_notif.outerHeight();
    let main_header_height = main_header.outerHeight();
    
    function doScrolling() {

        if (!loadingFooter && window.isInViewport(footer_wrapper)) {
            loadingFooter = true;
            !footerLoaded && import(/* webpackChunkName: "footer" */'../components/footer')
                .then(module => {
                    loadingFooter = false;
                    footerLoaded = true;
                    module.default();
                });
        }


        //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf

        if (!loadingLazyImagesBF && !lazyImagesBFLoaded && lazyImage_wrapper.length > 0 && window.isInViewport(lazyImage_wrapper)) {
            loadingLazyImagesBF = true;
            if(!lazyImagesBFLoaded) {
                lazyImageBF();
                loadingLazyImagesBF = false;
                lazyImagesBFLoaded = true;
            }
        }

        if (window.readcookie('fx_cn') * 1 !== 1) {
            //if cookie floater shown
            if ($(window).scrollTop() > 0) {
                //if user already scrolled
                 main_header.css('top', -(cookie_notif_height));

            } else {
                //if user NOT scrolled
                main_header.css('top', 0);
            }
        }

    }


    if($(window).width() > 620){
        var mobileImage = [].slice.call(document.getElementsByClassName("lazy-image-bf-mob"));{
            mobileImage.forEach.call(mobileImage, function (mobileImage) {
                mobileImage.src = mobileImage.dataset.src;
                mobileImage.classList.remove("lazy-image-bf-mob");
            });
        }
    }


    $(window).on('scroll resize', () => { scrolling = true; } );

    window.scrollingTasks.push(doScrolling);

    function scrollingTimer() {
        if($(document).height() <= $(window).height()){
            scrolling = true;
        }

        if($(window).scrollTop()>1){
            scrolling = true;
        }

        if (scrolling) window.scrollingTasks.forEach(function (task) {
            task();
        });
        window.setTimeout(scrollingTimer,250);
    }

        

    if($(".BlockedIPUSA").length == 0) {
        scrollingTimer();
    }

})(window.jQuery);